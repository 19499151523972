import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// MUI
import { makeStyles } from "@mui/styles";
import { Box, Grid, Typography } from "../../DesignData/MaterialUi/material-ui";

// CSS
import "./Contactus.css";

// Images
import {
  outdoorBanner,
  ourServicesbanner,
  weSeeThingsBgImage,
  CallIcon,
  locationMail,
  locationMap,
} from "../../DesignData/Images/Images";

import {
  TopBannerMainTitle,
  ContactFormSignUpInputs,
  ContactFormSignUpMessage,
  ContactformStateAndCityStyleForMobile,
  ContactFormSignUpInputsForMobile,
  ContactformStateAndCityStyleForDesktop,
  googlemapimagestyle,
  corporateofficetext,
  maineaddresstext,
  callIconAndNumber,
  calliconStyle,
  numberStyle,
  loactionmailstyle,
  emailAddressStyle,
  ContactusmainMargin,
} from "../Styles/Styles";

import { Helmet } from "react-helmet";

import { IterestedIn } from "../../assets/json/newDesign/InterestedIn";
import { indianStates } from "../../assets/json/newDesign/IndianStates";

const useStyles = makeStyles(() => ({
  bgImageDesktop: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "400px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bgImageMobile: {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${outdoorBanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "250px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  OurServicsBanner: {
    backgroundImage: `url(${ourServicesbanner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  aboutOutdoorAdver: {
    backgroundImage: `url(${weSeeThingsBgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  sideGridBgColor: {
    // backgroundColor: "yellow",
  },
  MainGridBgColor: {
    // backgroundColor: "pink",
  },
}));

const Contactus = () => {
  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_no: "",
    email_id: "",
    state: "",
    interest: "",
    message: "",
    receive_updates: false,
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async () => {
    // Basic validation
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.phone_no ||
      !formData.email_id ||
      !formData.state ||
      !formData.interest ||
      !formData.message
    ) {
      toast.error("Please fill out all required fields.");
      return;
    }

    try {
      const response = await fetch(
        "https://www.januskoncepts.in/janusoohadmin/api/contact_form/contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formData).toString(),
        }
      );

      const data = await response.json();

      if (data.success) {
        toast.success("Form submitted successfully!");
        setFormData({
          first_name: "",
          last_name: "",
          phone_no: "",
          email_id: "",
          state: "",
          interest: "",
          message: "",
          receive_updates: false,
        });
        setIsChecked(false); // Reset checkbox
      } else {
        toast.error("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const openGoogleMaps = () => {
    const lat = 28.62823572660926;
    const lng = 77.21988389466702;

    // Construct the Google Maps URL
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;

    window.open(googleMapsUrl, "_blank");
  };

  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>ReachOut Hoarding: Your Best Outdoor Advertising Partner</title>
        <meta
          name="description"
          content={
            "Contact Reach Out Hoarding for top-notch outdoor hoarding advertising partner. Let us amplify your brand's visibility and impact on the streets"
          }
        />
      </Helmet>

      <ToastContainer />

      <Box sx={{ backgroundColor: "#F0F0F0" }}>
        {/* Banner */}
        <Box>
          {/* desktop */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Grid container className={classes.bgImageDesktop}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={TopBannerMainTitle}>
                      {" "}
                      CONTACT <span style={{ color: "#FF1F2F" }}>US</span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>
          {/* mobile */}
          <Box
            sx={{
              display: {
                md: "none",
                lg: "none",
              },
            }}
          >
            <Grid container className={classes.bgImageMobile}>
              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={11}
                lg={11}
                className={classes.MainGridBgColor}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography sx={TopBannerMainTitle}>
                      {" "}
                      CONTACT <span style={{ color: "#FF1F2F" }}>US</span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={0.5}
                lg={0.5}
                className={classes.sideGridBgColor}
              ></Grid>
            </Grid>
          </Box>
        </Box>

        {/* Form */}
        <Box sx={ContactusmainMargin}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={0.5}
              lg={0.5}
              // sx={{ backgroundColor: "green" }}
            ></Grid>
            {/* Address */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4.5}
              lg={4.5}
              // sx={{ backgroundColor: "pink" }}
            >
              <Box
                sx={{
                  height: "100%",
                  padding: {
                    xs: "0px 10px 0px 10px",
                    sm: "0px 10px 0px 10px",
                    md: "0px",
                    lg: "0px",
                  },
                }}
              >
                {/* title */}
                <Box>
                  <Typography sx={corporateofficetext}>
                    Corporate Office
                  </Typography>
                </Box>
                {/* address/ */}
                <Box
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "20px",
                      lg: "20px",
                    },
                  }}
                >
                  <Typography sx={maineaddresstext}>
                    Head Office 66 , Second Floor , Atul grove
                    <br /> Road , Connaught Place , New <br /> Delhi , Delhi
                    110001
                  </Typography>
                </Box>
                {/* phone no */}
                <Box
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "30px",
                      lg: "30px",
                    },
                  }}
                >
                  <Box sx={callIconAndNumber}>
                    <Box component="img" src={CallIcon} sx={calliconStyle} />
                    <Typography sx={numberStyle}>+91 96507-64004</Typography>
                  </Box>
                </Box>
                {/* email */}
                <Box
                  sx={{
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "20px",
                      lg: "20px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      src={locationMail}
                      sx={loactionmailstyle}
                    />
                    <Typography sx={emailAddressStyle}>
                      birender@januskoncepts.com
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            {/* Form */}

            {/* Desktop */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6.5}
              lg={6.5}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              {/* First Name and Last Name */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  placeholder="First name*"
                  autoComplete="off"
                  style={ContactFormSignUpInputs}
                  className="custom-input-with-placeholder"
                />
                <input
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  placeholder="Last name*"
                  autoComplete="off"
                  style={ContactFormSignUpInputs}
                  className="custom-input-with-placeholder"
                />
              </Box>

              {/* Phone and Email */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: {
                    xs: "10px",
                    sm: "10px",
                    md: "35px",
                    lg: "35px",
                  },
                }}
              >
                <input
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleInputChange}
                  placeholder="Phone no*"
                  autoComplete="off"
                  style={ContactFormSignUpInputs}
                  className="custom-input-with-placeholder"
                />
                <input
                  name="email_id"
                  value={formData.email_id}
                  onChange={handleInputChange}
                  placeholder="Email id*"
                  autoComplete="off"
                  style={ContactFormSignUpInputs}
                  className="custom-input-with-placeholder"
                />
              </Box>

              {/* city and interest */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: {
                    xs: "10px",
                    sm: "10px",
                    md: "35px",
                    lg: "35px",
                  },
                }}
              >
                {/* State */}
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  style={{
                    ...ContactformStateAndCityStyleForDesktop,
                    color: formData.state ? "black" : "gray",
                  }}
                >
                  <option value="">State*</option>
                  {indianStates.map((item) => (
                    <option key={item.id} value={item.stateName}>
                      {item.stateName}
                    </option>
                  ))}
                </select>

                {/* Interest */}
                <select
                  name="interest"
                  value={formData.interest}
                  onChange={handleInputChange}
                  style={{
                    ...ContactformStateAndCityStyleForDesktop,
                    color: formData.interest ? "black" : "gray",
                  }}
                >
                  <option value="">What are you interested in*</option>
                  {IterestedIn.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Box>

              {/* Message */}
              <Box sx={{ display: "flex", marginTop: "35px" }}>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Message*"
                  autoComplete="off"
                  style={ContactFormSignUpMessage}
                  className="custom-input-with-placeholder"
                />
              </Box>

              {/* Checkbox */}
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    handleInputChange(e);
                  }}
                  name="receive_updates"
                />
                <label style={{ marginLeft: "10px" }}>
                  I want to receive updates
                </label>
              </Box>

              {/* Submit Button */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "30px",
                }}
                onClick={handleSubmit}
              >
                <Box
                  sx={{
                    width: "120px",
                    height: "50px",
                    border: "1px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    Submit
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Mobile */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6.5}
              lg={6.5}
              // sx={{ backgroundColor: "orange" }}
              sx={{
                display: {
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <Box
                sx={{
                  padding: "0px 10px 0px 10px",
                  marginTop: "20px",
                }}
              >
                {/* First Name and Last Name */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <input
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    placeholder="First name*"
                    autoComplete="off"
                    style={ContactFormSignUpInputsForMobile}
                    className="custom-input-with-placeholder"
                  />

                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <input
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      placeholder="Last name*"
                      autoComplete="off"
                      style={ContactFormSignUpInputsForMobile}
                      className="custom-input-with-placeholder"
                    />
                  </Box>
                </Box>

                {/* Phone and Email*/}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ marginTop: "10px" }}>
                    <input
                      name="phone_no"
                      value={formData.phone_no}
                      onChange={handleInputChange}
                      placeholder="Phone No*"
                      autoComplete="off"
                      style={ContactFormSignUpInputsForMobile}
                      className="custom-input-with-placeholder"
                    />
                  </Box>
                  <Box sx={{ marginTop: "10px" }}>
                    <input
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleInputChange}
                      placeholder="Email id*"
                      autoComplete="off"
                      style={ContactFormSignUpInputsForMobile}
                      className="custom-input-with-placeholder"
                    />
                  </Box>
                </Box>

                {/* city and interested */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* city */}
                  <Box sx={{ marginTop: "10px" }}>
                    <select
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      style={{
                        ...ContactformStateAndCityStyleForMobile,
                        color: formData.state ? "black" : "gray",
                      }}
                    >
                      <option value="">States*</option>
                      {indianStates.map((item) => (
                        <option key={item.id} value={item.stateName}>
                          {item.stateName}
                        </option>
                      ))}
                    </select>
                  </Box>

                  {/* interested */}
                  <Box sx={{ marginTop: "10px" }}>
                    <select
                      name="interest"
                      value={formData.interest}
                      onChange={handleInputChange}
                      style={{
                        ...ContactformStateAndCityStyleForMobile,
                        color: formData.interest ? "black" : "gray",
                      }}
                    >
                      <option value="">What are you interested in*</option>
                      {IterestedIn.map((item) => (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </Box>
                </Box>

                {/* Message*/}
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <input
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder="Message*"
                    autoComplete="off"
                    style={ContactFormSignUpMessage}
                    className="custom-input-with-placeholder"
                  />
                </Box>

                {/* CheckBox */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "30px",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(e.target.checked);
                      handleInputChange(e);
                    }}
                    style={{ width: "18px", height: "18px" }}
                    name="receive_updates"
                  />

                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      fontWeight: "400",
                      fontFamily: "Inter, sans-serif",
                    }}
                  >
                    Receive update on whatsapp
                  </Typography>
                </Box>
                {/* Button */}
                <Box
                  sx={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={handleSubmit}
                >
                  <Box
                    sx={{
                      width: "120px",
                      height: "50px",
                      border: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      Submit
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={0.5}
              lg={0.5}
              // sx={{ backgroundColor: "green" }}
            ></Grid>
          </Grid>
        </Box>

        <Grid container>
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} lg={11}>
            <Box
              sx={{
                marginTop: {
                  xs: "20px",
                  sm: "20px",
                  md: "0px",
                  lg: "0px",
                },
                marginBottom: {
                  xs: "10px",
                  sm: "10px",
                  md: "60px",
                  lg: "60px",
                },
                padding: {
                  xs: "0px 10px 0px 10px",
                  sm: "0px 10px 0px 10px",
                  md: "0px 0px 0px 0px",
                  lg: "0px 0px 0px 0px",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "0.5px",
                  backgroundColor: "#FF1F2F",
                }}
              ></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
        </Grid>

        {/* location map */}
        <Grid container>
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
          <Grid item xs={12} sm={12} md={11} lg={11}>
            {/* <Box
              sx={{
                marginBottom: {
                  xs: "20px",
                  sm: "20px",
                  md: "60px",
                  lg: "60px",
                },
                //  backgroundColor: "#F0F0F0"
                padding: {
                  xs: "0px 10px 0px 10px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                },
              }}
            >
              <Box
                component="img"
                src={locationMap}
                sx={googlemapimagestyle}
                onClick={openGoogleMaps}
              />
            </Box> */}
            <Box
              sx={{
                marginBottom: {
                  xs: "20px",
                  sm: "20px",
                  md: "60px",
                  lg: "60px",
                },
                padding: {
                  xs: "0px 10px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                },
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3502.050928930041!2d77.21730897550061!3d28.628235675667135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzQxLjciTiA3N8KwMTMnMTEuNiJF!5e0!3m2!1sen!2sin!4v1727529992766!5m2!1sen!2sin"
                width="100%" // Set width to 100% for responsiveness
                height="450"
                style={{ border: 0 }} // Use object notation for inline styles
                allowFullScreen // Use camelCase
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" // Use camelCase
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={0.5} lg={0.5}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contactus;
